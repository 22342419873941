export default {
  methods: {
    ForNumber: function (val) {
      let str = val.toLocaleString();
      str = str.replace(/,/g, 'c');
      str = str.replace(/c/g, '.');
      if (str.length < 1) {
        return 0;
      }
      return str;
    },
  },
};
