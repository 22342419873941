export default {
    methods: {
      ForDecimal: function(val) {
        let str = val.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
  
        str = str.replace(/,/g, "c");
        str = str.replace(".", "s");
  
        str = str.replace("s", ",");
        str = str.replace(/c/g, ".");
  
        return str;
      },
    },
  };